var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card-actions', {
    staticClass: "card-statistics",
    attrs: {
      "no-body": "",
      "title": "Rekap Status",
      "action-collapse": ""
    }
  }, [_c('b-card-body', {
    staticClass: "statistics-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-md-0",
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-2"
  }, [_c('b-avatar', {
    attrs: {
      "size": "48",
      "variant": "secondary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "CircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(" " + _vm._s(_vm.itemsrekap.pending) + " ")]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Pending ")])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0",
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-2"
  }, [_c('b-avatar', {
    attrs: {
      "size": "48",
      "variant": "warning"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "LoaderIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(" " + _vm._s(_vm.itemsrekap.proses) + " ")]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Proses ")])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0",
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-2"
  }, [_c('b-avatar', {
    attrs: {
      "size": "48",
      "variant": "success"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "CheckCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(" " + _vm._s(_vm.itemsrekap.selesai) + " ")]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Selesai ")])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0",
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-media', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-2"
  }, [_c('b-avatar', {
    attrs: {
      "size": "48",
      "variant": "danger"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "24",
      "icon": "XCircleIcon"
    }
  })], 1)], 1), _c('b-media-body', {
    staticClass: "my-auto"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(" " + _vm._s(_vm.itemsrekap.dibatalkan) + " ")]), _c('b-card-text', {
    staticClass: "font-small-3 mb-0"
  }, [_vm._v(" Batal ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "action-collapse": "",
      "title": "Daftar Ajuan Komisi"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('h5', [_vm._v("Total Komisi Rp. " + _vm._s(_vm.formatRupiah(_vm.totalKomisi)))]), _vm.cek.length > 0 ? [_c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "block": "",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.approveajuanproses();
      }
    }
  }, [_vm._v("Approve (" + _vm._s(this.cek.length == 0 ? null : this.cek.length) + ")")])] : [_c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "block": "",
      "variant": "secondary",
      "disabled": ""
    },
    on: {
      "click": function click($event) {
        return _vm.approveajuan();
      }
    }
  }, [_vm._v("Approve")])], _c('b-modal', {
    attrs: {
      "hide-backdrop": "",
      "no-close-on-esc": "",
      "no-close-on-backdrop": "",
      "id": "modal-konfirmasi",
      "size": "md",
      "ok-variant": "secondary",
      "centered": "",
      "title": 'Approve Komisi'
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showKonfirmasi = false;
              _vm.getDataKomisi();
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showKonfirmasi,
      callback: function callback($$v) {
        _vm.showKonfirmasi = $$v;
      },
      expression: "showKonfirmasi"
    }
  }, [_c('validation-observer', {
    ref: "vkonfirmasi"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Transaksi"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 1,
        text: 'Debit'
      }, {
        value: 2,
        text: 'Kredit'
      }],
      "id": "v-transaction_type",
      "name": "transaction_type"
    },
    model: {
      value: _vm.transactionType,
      callback: function callback($$v) {
        _vm.transactionType = $$v;
      },
      expression: "transactionType"
    }
  })], 1)], 1), _vm.transactionType ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Akun"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "id_akun"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.id_akun,
            "id": "v-id_akun",
            "name": "id_akun"
          },
          model: {
            value: _vm.formkonfirmasi.id_akun,
            callback: function callback($$v) {
              _vm.$set(_vm.formkonfirmasi, "id_akun", $$v);
            },
            expression: "formkonfirmasi.id_akun"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4095445891)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Kas"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "id_kas"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.id_kas,
            "id": "v-id_kas",
            "name": "id_kas"
          },
          model: {
            value: _vm.formkonfirmasi.id_kas,
            callback: function callback($$v) {
              _vm.$set(_vm.formkonfirmasi, "id_kas", $$v);
            },
            expression: "formkonfirmasi.id_kas"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Status"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.statuskomisi,
            "id": "v-status",
            "name": "status"
          },
          model: {
            value: _vm.formkonfirmasi.statuskomisi,
            callback: function callback($$v) {
              _vm.$set(_vm.formkonfirmasi, "statuskomisi", $$v);
            },
            expression: "formkonfirmasi.statuskomisi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submitkonfirmasi($event);
      }
    }
  }, [_vm._v(" Konfirmasi ")])], 1)], 2), _c('b-col', {
    attrs: {
      "xl": "9",
      "lg": "9",
      "md": "9",
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Per page",
      "label-size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "options": _vm.pageOptions,
      "size": "sm"
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sort",
      "label-size": "sm"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter",
      "label-size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "size": "sm",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter,
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipe Komisi",
      "label-size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "selectedjenis",
      "options": _vm.jenisOptions,
      "size": "sm"
    },
    on: {
      "input": function input($event) {
        return _vm.getDataKomisi();
      }
    },
    model: {
      value: _vm.selectedjenis,
      callback: function callback($$v) {
        _vm.selectedjenis = $$v;
      },
      expression: "selectedjenis"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "selectedStatus",
      "options": _vm.statusOptions,
      "size": "sm"
    },
    on: {
      "input": function input($event) {
        return _vm.getDataKomisi();
      }
    },
    model: {
      value: _vm.selectedStatus,
      callback: function callback($$v) {
        _vm.selectedStatus = $$v;
      },
      expression: "selectedStatus"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bundle",
      "label-size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "selectedjenis",
      "options": _vm.BundleOptions,
      "size": "sm"
    },
    on: {
      "input": function input($event) {
        return _vm.getDataKomisi();
      }
    },
    model: {
      value: _vm.is_bundled,
      callback: function callback($$v) {
        _vm.is_bundled = $$v;
      },
      expression: "is_bundled"
    }
  })], 1)], 1)], 1)], 1), _vm.isAdminGudang ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(urutan)",
      fn: function fn(_ref4) {
        var index = _ref4.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(total)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.total)) + " ")];
      }
    }, {
      key: "cell(tanggal)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal)) + " ")];
      }
    }, {
      key: "cell(diberikan)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.iberi[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.iberi[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(diterima)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.iterima[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.iterima[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(status2)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.getInfo(item).variant
          }
        }, [_vm._v(" " + _vm._s(_vm.getInfo(item).status) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Detail',
            expression: "'Detail'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$router.push("/biaya-operasional/detail/".concat(row.item.id));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EyeIcon"
          }
        })], 1), _vm.allowUpdate() && row.item.selesai == 0 && row.item.tolak == 0 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() && row.item.selesai == 0 && row.item.tolak == 0 ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 268874591)
  })], 1) : _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fieldsFinance,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(urutan)",
      fn: function fn(_ref8) {
        var index = _ref8.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(cek)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.status == 'pending' || item.status == 'proses' ? _c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.cek,
            callback: function callback($$v) {
              _vm.cek = $$v;
            },
            expression: "cek"
          }
        }) : _vm._e()];
      }
    }, {
      key: "cell(diberikan)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.iberi[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.iberi[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.statuswarna[1][item.status]
          }
        }, [_vm._v(" " + _vm._s(_vm.statuswarna[0][item.status]) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.typewarna[1][item.type]
          }
        }, [_vm._v(" " + _vm._s(_vm.typewarna[0][item.type]) + " ")])];
      }
    }, {
      key: "cell(nominal)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.nominal)) + " ")];
      }
    }, {
      key: "cell(tanggal)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(_vm.humanDate(item.tanggal)) + " ")];
      }
    }, {
      key: "cell(diterima)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.iterima[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.iterima[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(status2)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.getInfo(item).variant
          }
        }, [_vm._v(" " + _vm._s(_vm.getInfo(item).status) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_vm.allowUpdate() && item.status != 'selesai' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Batalkan Ajuan Komisi',
            expression: "'Batalkan Ajuan Komisi'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-50",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.batalkan(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XCircleIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() && item.status == 'dibatalkan' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-50",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }